import React from "react";
import PropTypes from "prop-types";
import { Alert, Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import { SET_KYC_STEP } from "../../store/user/actionTypes";

const StepIntro = ({ t }) => {
  let dispatch = useDispatch();

  const handleNext = () => {
    dispatch({ type: SET_KYC_STEP, step: "signIn" });
  };

  return (
    <>
      <Alert variant="">
        <strong>{t("Know Your Customer (KYC) Screening Requirement")}</strong>
        <p>
          {t(
            "To use Ensuro's products and services, you must complete our Know Your Customer (KYC) screening via Idenfy. This process is essential for protecting you and our platform from fraudulent and illegal activities. Your personal information will be processed by Idenfy in accordance with their privacy policy."
          )}
        </p>
      </Alert>
      <Modal.Footer>
        <Button variant="primary" onClick={handleNext}>
          {t("Next")}
        </Button>
      </Modal.Footer>
    </>
  );
};

StepIntro.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(StepIntro);
