import React from "react";
import PropTypes from "prop-types";
import { Alert, Button, Modal } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import { selectUserAddress, selectKycProcess, selectKycStatus, selectKycSignature } from "../../store/user/selectors";
import { selectCurrent } from "../../store/eTokens/selectors";
import { withTranslation } from "react-i18next";
import { RESET_KYC_PROCESS } from "../../store/user/actionTypes";

const StepKycStatus = ({ t, userAddress, kycStatus, email, etkWhitelist, kycSignature, onHide }) => {
  let dispatch = useDispatch();

  const getStatusMessage = () => {
    switch (kycStatus) {
      case "expired":
        return t("Your session has expired. Press below to try again.");
      case "rejected":
        return t(
          "We reviewed your information and decided we can't allow you to operate on our platform. If you think this is a mistake please contact support."
        );
      default:
        return;
    }
  };

  const handleTryAgain = () => {
    if (kycSignature) {
      const action = {
        type: "API_CALL",
        apiName: "storeKYC",
        args: [userAddress],
        method: "POST",
        data: {
          address: userAddress,
          message: kycSignature.message,
          signature: kycSignature.signature,
          email: kycSignature.email || "",
          start_kyc: "idenfy",
          data: {},
        },
      };
      dispatch(action);
    } else {
      dispatch({ type: RESET_KYC_PROCESS });
    }
  };

  return (
    <>
      <Alert variant={kycStatus === "rejected" ? "danger" : ""}>{getStatusMessage()}</Alert>
      <Modal.Footer>
        {kycStatus === "expired" ? (
          <Button className="align-end" variant="primary" onClick={handleTryAgain}>
            {t("Try again")}
          </Button>
        ) : (
          <Button className="align-end" variant="primary" onClick={onHide}>
            {t("Close")}
          </Button>
        )}
      </Modal.Footer>
    </>
  );
};

StepKycStatus.propTypes = {
  t: PropTypes.any,
  userAddress: PropTypes.string,
  kycStatus: PropTypes.string,
  email: PropTypes.string.isRequired,
  etkWhitelist: PropTypes.object,
  kycSignature: PropTypes.object,
  onHide: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const userAddress = selectUserAddress(state.UserReducer);
  const kycStatus = selectKycStatus(state.UserReducer);
  const { email } = selectKycProcess(state.UserReducer);
  const etk = selectCurrent(state.ETokensReducer);
  const etkWhitelist = etk && etk.whitelist;
  const kycSignature = selectKycSignature(state.UserReducer);

  return {
    userAddress,
    kycStatus,
    email,
    etkWhitelist,
    kycSignature,
  };
};

export default connect(mapStateToProps)(withTranslation()(StepKycStatus));
