import { createSelector } from "reselect";
import { getProvider } from "../../providers";
import { validExpiration } from "../../helpers/date_helper";

const getAddress = (state) => state.address;
const getProviderId = (state) => state.providerId;
const getChainId = (state) => state.chainId;
const getSigns = (state) => state.signs;
const getKycProcess = (state) => state.kycProcess;

const getSignKey = (__, key, address) => {
  return `${key}_${address}`;
};

export const selectUserAddress = createSelector([getAddress], (address) => address);

export const selectUserAddressShort = createSelector([getAddress], (address) =>
  address ? address.substring(0, 4) + "..." + address.slice(-4) : "noaddress"
);

export const selectProvider = createSelector(getProviderId, (providerId) => getProvider(providerId));

export const selectChainId = createSelector([getChainId], (chain) => chain);

export const selectSignsByKey = createSelector([getSigns, getSignKey], (signs, signKey) => signs[signKey]);

export const selectKycProcess = createSelector([getKycProcess], (kycProcess) => kycProcess);

export const selectKycStatus = createSelector([selectKycProcess], (kycProcess) => kycProcess.kycStatus);
export const selectAmlPass = createSelector([selectKycProcess], (kycProcess) => kycProcess.amlPass);
export const selectSanctionsPass = createSelector([selectKycProcess], (kycProcess) => kycProcess.sanctionsPass);

export const selectKycSignature = createSelector([getKycProcess], (kycProcess) => {
  return validExpiration(kycProcess.kycSignature?.expiration) ? kycProcess.kycSignature : null;
});
