import React from "react";
import PropTypes from "prop-types";
import { Alert, Form, Button, Modal } from "react-bootstrap";
import { SiweMessage } from "@ensuro/siwe";
import { chain, kyc } from "../../config";
import { getExpirationDate } from "../../helpers/date_helper";
import { selectUserAddress, selectKycProcess } from "../../store/user/selectors";
import { selectCurrent } from "../../store/eTokens/selectors";
import { connect, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import { STORE_USER_KYC, UPDATE_KYC_FORM } from "../../store/user/actionTypes";

const StepSignIn = ({
  t,
  userAddress,
  canTransact,
  etkWhitelist,
  email,
  termsChecked,
  cyberSecurityChecked,
  taxImplicationsChecked,
}) => {
  let dispatch = useDispatch();

  const invalidMail = () => {
    return !email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const handleLinkClick = (e) => {
    e.stopPropagation();
  };

  const getStatement = () => {
    return `Email address: ${email}. I accept the Ensuro Terms of Service. I am aware of the Cyber security considerations. I am aware of the Tax implications. See resources below.`;
  };

  const message =
    userAddress &&
    new SiweMessage({
      domain: window.location.host,
      address: userAddress,
      statement: getStatement(),
      uri: window.location.origin,
      version: "1",
      chainId: chain.id,
      expirationTime: getExpirationDate(),
      resources: [
        `${kyc.tos.url}?hash=${kyc.tos.hash}&last_updated=${kyc.tos.last_updated}`,
        `${kyc.cyber.url}?hash=${kyc.cyber.hash}&last_updated=${kyc.cyber.last_updated}`,
        `${kyc.tax.url}?hash=${kyc.tax.hash}&last_updated=${kyc.tax.last_updated}`,
      ],
    });

  const signMessage = async () => {
    try {
      let args = { email: email, whitelist: etkWhitelist };
      const m = message.prepareMessage();
      dispatch({ type: STORE_USER_KYC, key: "ensuroTerms", userAddress: userAddress, args: args });
      dispatch({ type: "ETH_PLAIN_SIGN", key: "ensuroTerms", message: m, userAddress: userAddress });
    } catch (addError) {
      throw addError;
    }
  };

  return (
    <>
      <Alert variant="">
        {t(
          "This information is required by Ensuro to comply with regulatory requirements. Ensuro will store this information."
        )}
      </Alert>
      <Form>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>{t("Email address")}</Form.Label>
          <Form.Control
            type="email"
            placeholder="name@example.com"
            isInvalid={email.length > 0 && invalidMail()}
            value={email}
            onChange={(e) => {
              dispatch({ type: UPDATE_KYC_FORM, payload: { email: e.target.value || "" } });
            }}
          />
          <Form.Control.Feedback type="invalid">{t("Please provide a valid mail.")}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3" controlId="ensuroTermsCheck">
          <Form.Check
            type="checkbox"
            label={
              <>
                {t("I accept the ")}
                <a
                  href={kyc.tos.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#007bff", textDecoration: "underline" }}
                  onClick={handleLinkClick}
                >
                  {t("Ensuro Terms of Service")}
                </a>
                {". "}
              </>
            }
            checked={termsChecked}
            onChange={(e) => {
              dispatch({ type: UPDATE_KYC_FORM, payload: { termsChecked: e.target.checked } });
            }}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="cyberSecurityCheck">
          <Form.Check
            type="checkbox"
            label={
              <>
                {t("I am aware of the  ")}
                <a
                  href={kyc.cyber.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#007bff", textDecoration: "underline" }}
                  onClick={handleLinkClick}
                >
                  {t("Cyber security considerations")}
                </a>
                {". "}
              </>
            }
            checked={cyberSecurityChecked}
            onChange={(e) => {
              dispatch({ type: UPDATE_KYC_FORM, payload: { cyberSecurityChecked: e.target.checked } });
            }}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="taxImplicationsCheck">
          <Form.Check
            type="checkbox"
            label={
              <>
                {t("I am aware of the  ")}
                <a
                  href={kyc.tax.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#007bff", textDecoration: "underline" }}
                  onClick={handleLinkClick}
                >
                  {t("Tax implications")}
                </a>
                {". "}
              </>
            }
            checked={taxImplicationsChecked}
            onChange={(e) => {
              dispatch({ type: UPDATE_KYC_FORM, payload: { taxImplicationsChecked: e.target.checked } });
            }}
          />
        </Form.Group>
      </Form>
      <Modal.Footer>
        <Button
          className="align-end"
          variant="primary"
          onClick={signMessage}
          disabled={
            !email.length ||
            invalidMail() ||
            !termsChecked ||
            !cyberSecurityChecked ||
            !taxImplicationsChecked ||
            !canTransact
          }
        >
          {t("Sign In")}
        </Button>
      </Modal.Footer>
    </>
  );
};

StepSignIn.propTypes = {
  t: PropTypes.any,
  userAddress: PropTypes.string,
  canTransact: PropTypes.bool.isRequired,
  etkWhitelist: PropTypes.object,
  email: PropTypes.string.isRequired,
  termsChecked: PropTypes.bool.isRequired,
  cyberSecurityChecked: PropTypes.bool.isRequired,
  taxImplicationsChecked: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const userAddress = selectUserAddress(state.UserReducer);
  const canTransact = state.UserReducer.canTransact;
  const etk = selectCurrent(state.ETokensReducer);
  const etkWhitelist = etk && etk.whitelist;
  const { email, termsChecked, cyberSecurityChecked, taxImplicationsChecked } = selectKycProcess(state.UserReducer);

  return {
    userAddress,
    canTransact,
    etkWhitelist,
    email,
    termsChecked,
    cyberSecurityChecked,
    taxImplicationsChecked,
  };
};

export default connect(mapStateToProps)(withTranslation()(StepSignIn));
