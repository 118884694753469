import React from "react";
import PropTypes from "prop-types";
/* i18n and Config */
import "./style.scss";

const IdenfyModal = ({ idenfyToken }) => {
  return (
    <>
      <iframe
        title="Idenfy"
        id="iframe"
        allowfullscreen
        style={{
          width: "100%",
          height: "650px",
          border: "none",
        }}
        src={`https://ui.idenfy.com/?authToken=${idenfyToken}`}
        allow="camera"
      ></iframe>
    </>
  );
};

IdenfyModal.propTypes = {
  t: PropTypes.any,
};

export default IdenfyModal;
