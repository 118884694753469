/* USER */
export const SET_PROVIDER = "SET_PROVIDER";
export const SET_USER = "SET_USER";
export const SET_CHAIN_ID = "SET_CHAIN_ID";

export const STORE_USER_KYC = "STORE_USER_KYC";
/**
 * @module ActionTypes
 * @description Action types related to the KYC (Know Your Customer) process in the Redux store.
 */

/**
 * Action to set the current step in the KYC process and update the screen showed in KYCFormModal.
 * @property {string} type - "SET_KYC_STEP"
 * @property {string} step - The current step in the KYC process to be setted ("intro", "signIn", "idenfy", "kycStatus")
 */
export const SET_KYC_STEP = "SET_KYC_STEP";

/**
 * Action to update KYC SignIn form fields.
 * This update is based on user input and persist in localStorage in case page is reloaded.
 * @property {string} type - "UPDATE_KYC_FORM"
 * @property {object} payload - Object with key-value, being each key the input in the form and the value is entered by the user.
 */
export const UPDATE_KYC_FORM = "UPDATE_KYC_FORM";

/**
 * Action to set the current KYC status.
 * This status indicates the progress in our KYC process.
 * @property {string} type - "SET_KYC_STATUS"
 * @property {string} kycStatus - The status in the KYC process.
 * @property {string} [authToken] - This key is optional, it is obtained only in storeKYC POST, saving the Idenfy token for the Idenfy Iframe.
 */
export const SET_KYC_STATUS = "SET_KYC_STATUS";

/**
 * Action to reset the KYC process
 * Resets the KYC process state and setting the step to the SignIn step. Used when the authToken is expired and need to obtain a new one.
 * @property {string} type - "RESET_KYC_PROCESS"
 */
export const RESET_KYC_PROCESS = "RESET_KYC_PROCESS";

/**
 * Action to set the Idenfy Token
 * Sets the authToken received from storeKYC API to be used in Idenfy Iframe. This one only resets or its cleared when the Idenfy process is finished with any output.
 * @property {string} type - "SET_IDENFY_TOKEN"
 * @property {string} idenfyToken - The Idenfy auth token to be set, the token obtaine in storeKYC response or null to clear it.
 */
export const SET_IDENFY_TOKEN = "SET_IDENFY_TOKEN";

export const SET_KYC_SIGNATURE = "SET_KYC_SIGNATURE";
