import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import { selectKycSignature, selectKycProcess } from "../../store/user/selectors";
import StepIntro from "./StepIntro";
import StepSignIn from "./StepSignIn";
import StepIdenfy from "./StepIdenfy";
import StepChecks from "./StepChecks";
import StepStatus from "./StepStatus";
import { SET_KYC_STEP } from "../../store/user/actionTypes";
import "./style.scss";

const KYCFormModal = ({ t, show, onHide, canTransact, kycProcess, kycSignature }) => {
  const dispatch = useDispatch();
  const { step } = kycProcess;

  useEffect(() => {
    if (step === "signIn" && kycSignature) {
      dispatch({ type: SET_KYC_STEP, step: "idenfy" });
    }
  }, [kycSignature, step, dispatch]);

  const getCurrentStep = (step) => {
    const steps = {
      intro: <StepIntro />,
      signIn: <StepSignIn />,
      idenfy: <StepIdenfy />,
      verificationDetails: <StepChecks onHide={onHide} />,
      kycStatus: <StepStatus onHide={onHide} />,
    };
    return steps[step] || null;
  };

  return (
    <Modal show={show} centered onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title className="link-style-title">{t("Identity verification")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{getCurrentStep(step)}</Modal.Body>
      {!canTransact && (
        <Modal.Footer>
          <p className="read-only">{t("Read-only mode. Connect to a wallet to perform transactions.")}</p>
        </Modal.Footer>
      )}
    </Modal>
  );
};

KYCFormModal.propTypes = {
  t: PropTypes.any,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  canTransact: PropTypes.bool,
  kycProcess: PropTypes.object.isRequired,
  kycSignature: PropTypes.object,
};

const mapStateToProps = (state) => {
  const canTransact = state.UserReducer.canTransact;
  const kycProcess = selectKycProcess(state.UserReducer);
  const kycSignature = selectKycSignature(state.UserReducer);

  return {
    canTransact,
    kycProcess,
    kycSignature,
  };
};

export default connect(mapStateToProps)(withTranslation()(KYCFormModal));
