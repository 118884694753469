function toList(envVar) {
  if (envVar === undefined) return [];
  if (envVar === "") return [];
  return envVar.split(",");
}

module.exports = {
  chain: {
    rpc: process.env.REACT_APP_CHAIN_RPC,
    id: parseInt(process.env.REACT_APP_CHAIN_ID),
    hexId: "0x" + parseInt(process.env.REACT_APP_CHAIN_ID).toString(16),
    network: process.env.REACT_APP_CHAIN_NETWORK,
    token: process.env.REACT_APP_CHAIN_TOKEN,
    name: process.env.REACT_APP_CHAIN_NAME,
    scan: process.env.REACT_APP_CHAIN_SCAN,
  },
  alchemy: {
    network: process.env.REACT_APP_ALCHEMY_NETWORK,
    apiKey: process.env.REACT_APP_ALCHEMY_API_KEY,
  },
  currency: {
    name: process.env.REACT_APP_CURRENCY_NAME || "USDC",
    address: process.env.REACT_APP_CURRENCY_ADDRESS,
    decimals: parseInt(process.env.REACT_APP_CURRENCY_DECIMALS),
  },
  faucet: {
    address: process.env.REACT_APP_FAUCET_ADDRESS,
    decimals: parseInt(process.env.REACT_APP_FAUCET_DECIMALS),
  },
  api: {
    url: process.env.REACT_APP_API_URL,
    clock: process.env.REACT_APP_API_CLOCK || 1000,
    clockCount: parseInt(process.env.REACT_APP_API_DEFAULT_CLOCK_COUNT || 600),
    retry: {
      timeout: parseInt(process.env.REACT_APP_API_RETRY_TIMEOUT || 1500),
      count: parseInt(process.env.REACT_APP_API_RETRY_COUNT || 10),
    },
    defaultMaxAge: parseInt(process.env.REACT_APP_API_DEFAULT_MAX_AGE || 60000),
  },
  environment: {
    name: process.env.REACT_APP_ENV_NAME,
    testnet: (process.env.REACT_APP_IS_TESTNET || "false") === "true",
    version: process.env.REACT_APP_ENV_VERSION || "development",
  },
  ethereum: {
    retry: {
      timeout: parseInt(process.env.REACT_APP_ETH_RETRY_TIMEOUT || 500),
      count: parseInt(process.env.REACT_APP_ETH_RETRY_COUNT || 10),
      transactCount: parseInt(process.env.REACT_APP_ETH_TRANSACT_COUNT || 300),
    },
    clock: process.env.REACT_APP_ETH_CLOCK || 500,
    clockCount: parseInt(process.env.REACT_APP_ETH_DEFAULT_CLOCK_COUNT || 600),
    defaultMaxAge: parseInt(process.env.REACT_APP_ETH_DEFAULT_MAX_AGE || 3000),
  },
  refresh: {
    default: parseInt(process.env.REACT_APP_DEFAULT_REFRESH || 120000),
    userInformation: parseInt(process.env.REACT_APP_REFRESH || 15000),
  },
  pool: {
    address: process.env.REACT_APP_POOL_ADDRESS,
  },
  persona: {
    templateId: process.env.REACT_APP_PERSONA_TEMPLATE_ID,
    environment: process.env.REACT_APP_PERSONA_ENVIRONMENT || "sandbox",
  },
  images: {
    defaultEtkLogo: "https://storage.googleapis.com/media-offchain-mumbai/media/etk_logos/default.png",
  },
  minicharts: {
    daysFrom: 15,
  },
  cashFlowCharts: {
    daysFrom: 30,
  },
  gas: {
    increase: 130, // 30%
  },
  rollbar: {
    accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN || "notoken",
    environment: process.env.REACT_APP_ROLLBAR_ENVIRONMENT,
    enabled: (process.env.REACT_APP_ROLLBAR_ENABLED || "false") === "true",
  },
  track: {
    name: "Track Wallet",
  },
  quadrata: {
    passportAddress: process.env.REACT_APP_QUADRATA_PASSPORT_ADDRESS,
    apiUrl: process.env.REACT_APP_QUADRATA_API_URL,
    tokenId: parseInt(process.env.REACT_APP_QUADRATA_TOKEN_ID) || 1,
    environment: process.env.REACT_APP_QUADRATA_ENVIRONMENT || "sandbox",
    requiredAttributes: toList(process.env.REACT_APP_QUADRATA_REQUIRED_ATTRIBUTES),
    privateData: toList(process.env.REACT_APP_QUADRATA_REQUIRED_PRIVATE_DATA),
    skipPrivateData: toList(process.env.REACT_APP_QUADRATA_SKIP_PRIVATE_DATA),
  },
  apy: {
    range: 30,
    maxAPY: 9.99, // 999%
  },
  walletConnect: {
    projectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID,
  },
  overrides: toList(process.env.REACT_APP_HAS_OVERRIDES),
  kyc: {
    tos: {
      url: process.env.REACT_APP_KYC_TOS_URL,
      hash: process.env.REACT_APP_KYC_TOS_HASH,
      last_updated: process.env.REACT_APP_KYC_TOS_LAST_UPDATED,
    },
    tax: {
      url: process.env.REACT_APP_KYC_TAX_URL,
      hash: process.env.REACT_APP_KYC_TAX_HASH,
      last_updated: process.env.REACT_APP_KYC_TAX_LAST_UPDATED,
    },
    cyber: {
      url: process.env.REACT_APP_KYC_CYBER_URL,
      hash: process.env.REACT_APP_KYC_CYBER_HASH,
      last_updated: process.env.REACT_APP_KYC_CYBER_LAST_UPDATED,
    },
  },
};
