import { call, put, takeEvery, take, select } from "redux-saga/effects";
import { eventChannel, END } from "redux-saga";
import { getProvider } from "../../providers";
import { getExpirationDate } from "../../helpers/date_helper";
import { getAPI } from "../../apiRegistry";
/* Selector */
import { selectSignsByKey } from "./selectors";
// Redux Actions
import { API_CALL_SUCCESS } from "@ensuro/api-calls-store/src/store/api/actionTypes";
import { ETH_PLAIN_SIGN_PROCESSED } from "@ensuro/ethereum-store/src/store/ethereum/actionTypes";
import { SET_PROVIDER, SET_USER, SET_CHAIN_ID } from "./actionTypes";

const Web3 = require("web3");

function providerListenerChannel(provider) {
  return eventChannel((emitter) => {
    /*if (provider.removeAllListeners)
      provider.removeAllListeners();*/
    provider.on("accountsChanged", (evtData) => {
      // Track Wallet can't emit this event, so it's always true
      emitter({ type: SET_USER, payload: evtData[0], canTransact: true });
    });

    provider.on("chainChanged", (evtData) => {
      emitter({ type: SET_CHAIN_ID, payload: parseInt(evtData, 16) });
    });

    provider.on("disconnect", () => {
      emitter({ type: SET_PROVIDER, payload: null });
      emitter(END);
    });
    return () => {};
  });
}

export function* listenToEvents({ payload }) {
  if (payload == null) return;
  const channel = yield call(providerListenerChannel, getProvider(payload));
  try {
    while (true) {
      let action = yield take(channel);
      yield put(action);
    }
  } finally {
  }
}

export function* storeUser({ key, userAddress, signature, message }) {
  const state = yield select((state) => state.UserReducer);
  const sign = selectSignsByKey(state, key, userAddress);
  if (userAddress !== state.address) {
    // We want to ignore any call made by another address than the state one.
    return;
  }
  const args = sign.args;

  const kycSignature = {
    message: message,
    signature: signature,
    expiration: getExpirationDate(),
    email: args.email,
  };

  yield put({ type: "SET_KYC_SIGNATURE", kycSignature });

  yield put({
    type: "API_CALL",
    apiName: "storeKYC",
    args: [userAddress],
    method: "POST",
    data: {
      address: userAddress,
      message: kycSignature.message,
      signature: kycSignature.signature,
      email: kycSignature.email,
      start_kyc: "idenfy",
      data: {},
    },
  });
}

export function* handleApiSuccess(action) {
  const result = action.value;
  if (!result) return;

  const userAddress = yield select((state) => state.UserReducer.address);
  const currentStep = yield select((state) => state.UserReducer.kycProcess.step);
  const checkSumUserAddress = userAddress ? Web3.utils.toChecksumAddress(userAddress) : null;

  if (checkSumUserAddress === null) return;
  if (action.call_key === getAPI("checkUser").urlFunction(checkSumUserAddress)) {
    yield put({
      type: "SET_KYC_STATUS",
      kycStatus: result.kyc_status,
      amlPass: result.aml_pass || null,
      sanctionsPass: result.sanctions_pass || null,
    });
    if (result.kyc_status === "expired" || result.kyc_status === "rejected") {
      yield put({ type: "SET_KYC_STEP", step: "kycStatus" });
    } else if (result.kyc_status && result.kyc_status !== "pending" && currentStep !== "verificationDetails") {
      yield put({ type: "SET_KYC_STEP", step: "verificationDetails" });
    }
  } else if (action.call_key === getAPI("storeKYC").urlFunction(checkSumUserAddress)) {
    yield put({
      type: "SET_KYC_STATUS",
      kycStatus: "pending",
      authToken: result.kyc_check_data?.data?.authToken,
    });
    yield put({ type: "SET_KYC_STEP", step: "idenfy" });
    yield put({ type: "SET_IDENFY_TOKEN", idenfyToken: result.kyc_check_data?.data?.authToken });
  }
}

export function* userSaga() {
  yield takeEvery(SET_PROVIDER, listenToEvents);
  yield takeEvery(ETH_PLAIN_SIGN_PROCESSED, storeUser);
  yield takeEvery(API_CALL_SUCCESS, handleApiSuccess);
}

export default userSaga;
