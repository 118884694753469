export const getExpirationDate = () => {
  let date = new Date();
  date.setDate(date.getDate() + 1);
  return date.toISOString();
};

export const validExpiration = (expiration) => {
  const currentDate = new Date();
  return expiration && currentDate < new Date(expiration);
};
