import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Alert, Button, Spinner, Modal } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import { selectEthCallMultiple } from "@ensuro/ethereum-store/src/store/ethereum/selectors";
import { selectCurrent } from "../../store/eTokens/selectors";
import { addRemoveApiSub } from "@ensuro/api-calls-store/src/utils/helpers/store_helper";
import { selectKycStatus, selectAmlPass, selectSanctionsPass, selectUserAddress } from "../../store/user/selectors";
import { withTranslation } from "react-i18next";

const makeWhitelistCall = (userAddress, etkAddress, etkWhitelist) => {
  return [
    {
      address: etkWhitelist.address,
      abi: "ILPWhitelist",
      method: "acceptsDeposit(address,address,uint256)",
      args: [etkAddress, userAddress, 1],
    },
    {
      address: etkWhitelist.address,
      abi: "ILPWhitelist",
      method: "acceptsWithdrawal(address,address,uint256)",
      args: [etkAddress, userAddress, 1],
    },
  ];
};

const apiCalls = (address) => [{ apiName: "checkUser", args: [address] }];

const StepChecks = ({
  t,
  userAddress,
  kycStatus,
  amlPass,
  sanctionsPass,
  acceptsDeposit,
  acceptsWithdrawal,
  onHide,
}) => {
  let dispatch = useDispatch();

  const getStatusIcon = (status) => {
    if (status === true) {
      return <span style={{ color: "green", fontSize: "1rem" }}>✅</span>;
    }
    if (status === false) {
      return <span style={{ color: "red", fontSize: "1rem" }}>❌</span>;
    }
    return <Spinner animation="border" size="sm" style={{ color: "gray" }} />;
  };

  const canDeposit = acceptsDeposit && acceptsDeposit.value;

  const canWithdraw = acceptsWithdrawal && acceptsWithdrawal.value;

  const canOperate = canDeposit || canWithdraw;

  const allApproved = kycStatus === "approved" && amlPass === true && sanctionsPass === true && canOperate === true;

  useEffect(() => {
    return addRemoveApiSub(dispatch, "checkUser", apiCalls(userAddress), 5);
  }, [userAddress, dispatch]);

  return (
    <>
      <Alert>
        <h5>{t("Verification checks")}</h5>
        <div className="mb-3">
          <span style={{ display: "flex", alignItems: "center" }}>
            {getStatusIcon(kycStatus === "approved" ? true : kycStatus === "pending" ? null : false)}
            <span style={{ marginLeft: "0.5rem" }}>{t("Identity Verification")}</span>
          </span>
        </div>
        <div className="mb-3">
          <span style={{ display: "flex", alignItems: "center" }}>
            {getStatusIcon(amlPass)}
            <span style={{ marginLeft: "0.5rem" }}>{t("AML Risk Score")}</span>
          </span>
        </div>
        <div className="mb-3">
          <span style={{ display: "flex", alignItems: "center" }}>
            {getStatusIcon(sanctionsPass)}
            <span style={{ marginLeft: "0.5rem" }}>{t("Sanctions Check")}</span>
          </span>
        </div>
        <div className="mb-3">
          <span style={{ display: "flex", alignItems: "center" }}>
            {getStatusIcon(canOperate ? true : null)}
            <span style={{ marginLeft: "0.5rem" }}>{t("Approved to Operate")}</span>
          </span>
        </div>
      </Alert>
      <Modal.Footer>
        <Button disabled={!allApproved} className="align-end" variant="primary" onClick={onHide}>
          {t("Finish")}
        </Button>
      </Modal.Footer>
    </>
  );
};

StepChecks.propTypes = {
  t: PropTypes.any,
  kycStatus: PropTypes.string,
  amlPass: PropTypes.bool,
  sanctionsPass: PropTypes.bool,
  canOperate: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const userAddress = selectUserAddress(state.UserReducer);
  const kycStatus = selectKycStatus(state.UserReducer);
  const amlPass = selectAmlPass(state.UserReducer);
  const sanctionsPass = selectSanctionsPass(state.UserReducer);
  const etk = selectCurrent(state.ETokensReducer);
  const etkAddress = etk?.address;
  const etkWhitelist = etk && etk.whitelist;
  const [acceptsDeposit, acceptsWithdrawal] =
    !userAddress || etkWhitelist === undefined
      ? [{}, {}]
      : etkWhitelist === null
      ? [
          { state: "LOADED", value: true },
          { state: "LOADED", value: true },
        ]
      : selectEthCallMultiple(state.EthereumReducer, makeWhitelistCall(userAddress, etkAddress, etkWhitelist));

  return {
    userAddress: userAddress,
    kycStatus: kycStatus,
    amlPass: amlPass,
    sanctionsPass: sanctionsPass,
    acceptsDeposit,
    acceptsWithdrawal,
  };
};

export default connect(mapStateToProps)(withTranslation()(StepChecks));
