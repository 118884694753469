// User Redux States
import {
  SET_USER,
  SET_PROVIDER,
  SET_CHAIN_ID,
  STORE_USER_KYC,
  SET_KYC_STEP,
  UPDATE_KYC_FORM,
  SET_KYC_STATUS,
  RESET_KYC_PROCESS,
  SET_IDENFY_TOKEN,
  SET_KYC_SIGNATURE,
} from "./actionTypes";

const Web3 = require("web3");

const INIT_STATE = {
  address: null,
  loadingState: "NOT_LOADED",
  // NOT_LOADED -> Loading -> LOADED
  //                       \-> ERROR
  error: {
    message: "",
  },
  chainId: null,
  providerId: null,
  canTransact: false,
  signs: {},
  kycProcess: {
    step: "intro",
    email: "",
    termsChecked: false,
    cyberSecurityChecked: false,
    taxImplicationsChecked: false,
    kycStatus: null,
    amlPass: null,
    sanctionsPass: null,
    kycSignature: null,
  },
};

function modifyNode(state, path, newValueFn) {
  if (path.length === 1) return { ...state, [path[0]]: newValueFn(state[path[0]] || {}) };
  else {
    return { ...state, [path[0]]: modifyNode(state[path[0]] || {}, path.slice(1, path.length), newValueFn) };
  }
}

const UserReducer = (state = INIT_STATE, action) => {
  let newState = state;
  switch (action.type) {
    case SET_USER:
      const checkSummedAddress = action.payload && Web3.utils.toChecksumAddress(action.payload);
      const userLocalStorage =
        JSON.parse(localStorage.getItem(`kycProcess_${checkSummedAddress}`)) || INIT_STATE.kycProcess; // Persistency in case reload page
      newState = {
        ...state,
        address: checkSummedAddress,
        canTransact: action.canTransact,
        kycProcess: userLocalStorage,
      };
      break;

    case SET_CHAIN_ID:
      newState = { ...state, chainId: Number(action.payload) };
      break;

    case SET_PROVIDER:
      newState = { ...state, providerId: action.payload };
      break;

    case STORE_USER_KYC:
      const key = `${action.key}_${action.userAddress}`;
      const dict = { userAddress: action.userAddress, args: action.args };
      newState = modifyNode(state, ["signs", key], () => dict);
      break;

    case SET_KYC_STEP:
      newState = {
        ...state,
        kycProcess: { ...state.kycProcess, step: action.step },
      };
      break;

    case UPDATE_KYC_FORM:
      newState = {
        ...state,
        kycProcess: { ...state.kycProcess, ...action.payload },
      };
      break;

    case SET_KYC_STATUS:
      newState = {
        ...state,
        kycProcess: {
          ...state.kycProcess,
          kycStatus: action.kycStatus,
          amlPass: action.amlPass !== undefined ? action.amlPass : state.kycProcess.amlPass,
          sanctionsPass: action.sanctionsPass !== undefined ? action.sanctionsPass : state.kycProcess.sanctionsPass,
        },
      };
      break;

    case RESET_KYC_PROCESS:
      newState = {
        ...newState,
        kycProcess: { ...INIT_STATE.kycProcess, step: "signIn" },
      };
      break;

    case SET_IDENFY_TOKEN:
      newState = { ...state, idenfyToken: action.idenfyToken };
      break;

    case SET_KYC_SIGNATURE:
      newState = {
        ...state,
        kycProcess: { ...state.kycProcess, kycSignature: action.kycSignature },
      };
      break;
    default:
      break;
  }

  if (newState.kycProcess !== state.kycProcess && newState.address) {
    localStorage.setItem(`kycProcess_${newState.address}`, JSON.stringify(newState.kycProcess));
  }

  return newState;
};

export default UserReducer;
