import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Loader from "react-js-loader";
import Idenfy from "./IdenfyModal";
import { connect, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import { SET_KYC_STEP, SET_IDENFY_TOKEN } from "../../store/user/actionTypes";

const StepIdenfy = ({ authToken }) => {
  let dispatch = useDispatch();
  useEffect(() => {
    const receiveMessage = (event) => {
      if (event.origin === "https://ui.idenfy.com" && event.data && event.data.status) {
        dispatch({ type: SET_KYC_STEP, step: "verificationDetails" });
        dispatch({ type: SET_IDENFY_TOKEN, idenfyToken: null });
      }
    };
    window.addEventListener("message", receiveMessage, false);
    return () => window.removeEventListener("message", receiveMessage, false);
  }, [dispatch]);

  if (!authToken) {
    return <Loader type="spinner-default" height={50} width={100} timeout={1000} />;
  }
  return <Idenfy idenfyToken={authToken} />;
};

StepIdenfy.propTypes = {
  t: PropTypes.any,
  authToken: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    authToken: state.UserReducer.idenfyToken,
  };
};

export default connect(mapStateToProps)(withTranslation()(StepIdenfy));
